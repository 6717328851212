<template>
  <div class="cont companies">
    <Breadcrumb :crumbs="crumbs"/>
    <el-row class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>订单编号：</label>
            <el-input v-model="params.orderNo" placeholder="订单编号" clearable></el-input>
          </el-row>
          <el-row class="search-item">
            <label>下单时间：</label>
            <date-packer :datePacker="dateRange" @setDatePacker="getDatePacker"></date-packer>
          </el-row>
          <el-button class="bg-gradient" type="primary" @click="handleQuery(true)">搜索</el-button>
          <el-button type="primary" plain @click="handleQuery(false)">重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <div class="table-head-btn">
          <el-tabs v-model="params.orderStatus" @tab-click="handleTab">
            <el-tab-pane v-for="(key, val) in { '': '全部', HAVE_PAID: '已付款', PENDING_PAYMENT: '待付款', CANCEL: '已取消', REFUNDED: '已退款' }"
                         :label="key" :name="val" :key="key"></el-tab-pane>
          </el-tabs>
        </div>
        <el-table ref="mainTable" v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="orderNo" label="订单编号" min-width="110"></el-table-column>
          <el-table-column label="商品类型" min-width="60">
            <template slot-scope="scope">{{ scope.row.orderType | filterOrderType(that) }}</template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column prop="reserveMobile" label="下单电话"></el-table-column>
          <el-table-column prop="actualAmount" label="实收金额"></el-table-column>
          <el-table-column prop="createTime" label="下单时间" width="160"></el-table-column>
          <el-table-column label="订单状态">
            <template slot-scope="scope">{{ scope.row.orderStatus | filterOrderStatus(that) }}</template>
          </el-table-column>
          <el-table-column prop="srcHotelName" label="供应酒店"></el-table-column>
          <el-table-column label="操作" fixed="right" min-width="80">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
    </el-row>
  </div>
</template>
<script>
import { getPage } from "@/api/shop/order/order";
import { getDict } from '@/common/js/common'
import { mapState } from 'vuex'
export default {
  data(){
    return{
      crumbs: new Map([
          ['特惠商城'], ['订单中心'], ['订单管理']
      ]),
      that: this,
      tableData: [],
      loading: true,
      params: {
        orderNo: '',
        orderStatus: '',
        beginTime: '',
        endTime: '',
        dataScope: 'MALL'
      },
      total: 0,
      page: 1,
      limit: 0,
      accountType: '',
      dateRange: [],
    }
  },
  computed:{
    ...mapState(['hotelInfo','dictData'])
  },
  mounted() {
    getDict(['order-type','order-status'])
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.accountType = JSON.parse(sessionStorage.getItem('userInfo')).accountType
    this.getOrderList()
  },
  methods: {
    // 获取订单列表
    getOrderList(){
      const query = `?page=${ this.page }&limit=${ this.limit }`,
            params = { ...this.params, hotelId: this.hotelInfo.id };
      params.orderStatus === '0' && (params.orderStatus = '')
      if (this.accountType === 'STORE') {
        params.companyId = this.hotelInfo.storeId
        params.hotelId = this.hotelInfo.id
      }
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(query, params).then(({ success, records, total }) => {
        if (!success) return;
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 获取订单时间
    getDatePacker(val){
      this.dateRange = val
      let [ beginTime = null, endTime = null ] = val
      this.params.beginTime = beginTime
      this.params.endTime = endTime
    },
    // 查询订单
    handleQuery(bool){
      this.page = 1
      if (bool) return this.getOrderList()
      for (const k in this.params) this.params[k] = ''
      this.params.dataScope = 'MALL'
      this.dateRange = []
    },
    // 改变显示条数
    changePageNum(num){
      this.limit = num
      this.getOrderList()
    },
    // 改变当前页
    handleCurrPage (num) {
      this.page = num
      this.getOrderList()
    },
    // 改变当前tab
    handleTab() {
      this.page = 1
      this.getOrderList()
    },
    // 查看详情
    handleDetail({ id }){
      this.$router.push({ path: '/preferential_mall/order_manage/detail', query: { id } })
    }
  },
  watch:{
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getOrderList()
    }
  },
  filters: {
    filterOrderStatus(val, that) {
      if (val && that.dictData['order-status']) {
        if (val === 'CHECKIN') return '已核销'
        else return that.dictData['order-status'][val] || val
      }
    },
    filterOrderType(val, that) {
      if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
    }
  }
}
</script>
<style lang="scss"></style>
